import React, { useState, useEffect, useRef } from "react";
import "./ChatUs.css";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";

const ChatUs = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to manage menu visibility
  const userId = localStorage.getItem("_id");
  const sidebarRef = useRef();

  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current && // Check if sidebarRef is available
        !sidebarRef.current.contains(event.target) && // Ensure click is outside the sidebar
        isMenuOpen // Only close if the menu is open
      ) {
        setIsMenuOpen(false); // Close the sidebar
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen, sidebarRef]);
  const handleSendMessage = () => {
    if (!inputMessage.trim()) return;

    const userMessage = { role: "user", content: inputMessage };
    setMessages((prev) => [...prev, userMessage]); // Append the user message first

    const botReply = generateResponse(inputMessage.toLowerCase());
    setMessages((prev) => [...prev, botReply]); // Add bot reply to the messages

    setInputMessage(""); // Clear the input field
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default behavior of Enter key
      handleSendMessage();
    }
  };

  const generateResponse = (message) => {
    // Simple rule-based logic for chatbot responses
    if (
      message.includes("hello") ||
      message.includes("hi") ||
      message.includes("hy")
    ) {
      return { role: "bot", content: "Hello! How can I assist you today?" };
    } else if (message.includes("site") || message.includes("about")) {
      return {
        role: "bot",
        content:
          "This site provides cryptocurrency trading and prediction services. Feel free to explore our features!",
      };
    } else if (message.includes("services") || message.includes("features")) {
      return {
        role: "bot",
        content:
          "Our services include live crypto trading, portfolio management, and real-time predictions. Let me know if you'd like more details!",
      };
    } else if (message.includes("help") || message.includes("support")) {
      return {
        role: "bot",
        content:
          "I'm here to help! You can ask about our features, trading tips, or anything else you'd like to know.",
      };
    } else {
      return {
        role: "bot",
        content: "I'm not sure about that. Could you please rephrase?",
      };
    }
  };

  return (
    <div className="container">
      <header>
        <div
          className="title-container"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <h1>
            <b style={{ fontSize: "25px" }} className="header-text">
              <Link to="/">TRCNXF</Link>
            </b>
          </h1>

          <button className="menu-button" onClick={toggleMenu}>
            &#9776;
          </button>
        </div>
      </header>
      <Sidebar
        sidebarRef={sidebarRef}
        // email={email}
        userId={userId}
        // kycStatus={kycStatus}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        // handleLogout={handleLogout}
      />
      <div className="chat-container">
        <h2>
          <b style={{ color: "var(--text-color)" }}>Chat with Us</b>
        </h2>
        <div className="chat-box">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`chat-message ${msg.role === "user" ? "user" : "bot"}`}
            >
              {msg.content}
            </div>
          ))}
        </div>
        <div className="chat-input">
          <input
            type="text"
            placeholder="Type your message..."
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={handleKeyDown} // Use onKeyDown instead of onKeyPress
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChatUs;
