import React from "react";
import { useFooter } from "./FooterContext"; // Adjust path as necessary
import { useNavigate } from "react-router-dom";
import Login from "./Login"; // Import the Login component directly

const Footer = () => {
  const { activeFooter, setActiveFooter } = useFooter();
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = React.useState(false); // State for the login modal

  const handleFooterClick = (route) => {
    const authToken = localStorage.getItem("authToken"); // Check for auth token

    if (!authToken) {
      setShowLoginModal(true); // Show the login modal if not logged in
      return;
    }

    setActiveFooter(route); // Update active footer
    navigate(route); // Navigate to the selected route
  };

  return (
    <>
      <div className="footer-nav">
        <ul className="footer-icons" style={{ padding: "10px 15px 20px 15px" }}>
          <li
            className={activeFooter === "/" ? "active" : ""}
            onClick={() => handleFooterClick("/")}
          >
            <i className="fas fa-home"></i>
            <span>Home</span>
          </li>
          <li
            className={activeFooter === "/tradepage" ? "active" : ""}
            onClick={() => handleFooterClick("/tradepage")}
          >
            <i className="fas fa-exchange-alt"></i>
            <span>Trade</span>
          </li>
          <li
            className={activeFooter === "/result" ? "active" : ""}
            onClick={() => handleFooterClick("/result")}
          >
            <i className="fas fa-chart-line"></i>
            <span>Result</span>
          </li>
          <li
            className={activeFooter === "/wallet" ? "active" : ""}
            onClick={() => handleFooterClick("/wallet")}
          >
            <i className="fas fa-wallet"></i>
            <span>Wallet</span>
          </li>
        </ul>
      </div>
      {showLoginModal && <Login closeModal={() => setShowLoginModal(false)} />}
    </>
  );
};

export default Footer;
