// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* :root {
  --background-color: #fff;
  --text-color: #000;
  --primary-color: #5358d5;
  --secondary-color: #7d9aea;
  --highlight-color: #fefefe;
  --modal-background: #fefefe;
}

[data-theme="dark"] {
  --background-color: #3f3f3f;
  --text-color: #f0f0f0;
  --primary-color: #3f3f3f;
  --secondary-color: #3f3f3f;
  --highlight-color: #3f3f3f;
  --modal-background: #3f3f3f;
} */

.summary-header {
  display: flex;
  align-items: center;
}

.summary-header img.logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.transaction-details .label {
  font-weight: bold;
}

.transaction-details .value {
  font-weight: normal;
}

.status.completed {
  color: green;
}

.status.pending {
  color: orange;
}
`, "",{"version":3,"sources":["webpack://./src/Components/TransactionSummary.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;GAgBG;;AAEH;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf","sourcesContent":["/* :root {\r\n  --background-color: #fff;\r\n  --text-color: #000;\r\n  --primary-color: #5358d5;\r\n  --secondary-color: #7d9aea;\r\n  --highlight-color: #fefefe;\r\n  --modal-background: #fefefe;\r\n}\r\n\r\n[data-theme=\"dark\"] {\r\n  --background-color: #3f3f3f;\r\n  --text-color: #f0f0f0;\r\n  --primary-color: #3f3f3f;\r\n  --secondary-color: #3f3f3f;\r\n  --highlight-color: #3f3f3f;\r\n  --modal-background: #3f3f3f;\r\n} */\r\n\r\n.summary-header {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.summary-header img.logo {\r\n  width: 50px;\r\n  height: 50px;\r\n  margin-right: 10px;\r\n}\r\n\r\n.transaction-details .label {\r\n  font-weight: bold;\r\n}\r\n\r\n.transaction-details .value {\r\n  font-weight: normal;\r\n}\r\n\r\n.status.completed {\r\n  color: green;\r\n}\r\n\r\n.status.pending {\r\n  color: orange;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
